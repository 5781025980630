import { PageProps } from 'gatsby';
import { ProfileData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/profile.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceProfilesCreate: FC<PageProps & EspaceProps & UserProps> =
  function ({ espace, user }) {
    const model = new ProfileData({ espace, params, espaceId: espace.id });

    return (
      <TemplateEspace espace={espace} user={user}>
        <CMSView>
          <Form
            docId={model.getNewDocId()}
            itemPathnamePrefix={`/espaces/${espace.id}/contacts/profiles/`}
            model={model}
            name="profile"
            type="create"
          />
        </CMSView>
      </TemplateEspace>
    );
  };

export default requireEspace(PageEspaceProfilesCreate);
